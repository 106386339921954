import React, { useState, useEffect, useCallback } from "react";
import Icofont from "react-icofont";
import DropdownMenu from "../Navs/DropdownMenu";
import SearchOverlay from "../../elements/SearchOverlay";
import AttributeNav from "../Navs/AttributeNav";
import MainLogo from "../MainLogo";
import nysFHN from "../../assets/images/nys_fhn.png";

import SearchItem from "../../elements/SearchItem";

const HeaderOne = ({ type }) => {
  const [show, setShow] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const showMenu = () => {
    setCollapse(!collapse);
    const menu = document.getElementById("navbar-menu");
    collapse ? menu.classList.remove("in") : menu.classList.add("in");
  };

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 34) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, []);

  const resizeForm = useCallback(() => {
    var wHeight = window.innerHeight;
    const searchForm = document.getElementById("fullscreen-searchform");
    searchForm.style.top = wHeight / 2 + "px";
  }, []);

  const showSearchForm = (e) => {
    e.preventDefault();
    setShow(true);
    resizeForm();
  };

  const hideSearchForm = (e) => {
    e.preventDefault();
    setShow(false);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeForm);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", resizeForm);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [resizeForm, handleScroll]);

  return (
    <>  

<div className="row dark-color fixed grey-bg">
          <div className="col-md-4 col-sm-4 ml-minus-20">
      
          <div id="google_translate_element"></div>

          </div>
          
      
          <div className="col-md-4  col-sm-4">
         
           
          </div>
          <div className="col-md-4 col-sm-4">
         
            <p>
           
            <i className="icofont icofont-iphone mr-5"></i>
                    <a href="tel:2127513300">212-751-3300</a>  
                    <i className="icofont icofont-mail  ml-20 mr-5"></i>
                    <a href="mailto:dso@olshan.com">
                      EMAIL
                    </a><img className="ml-30" src={nysFHN} alt="" />

            </p>
          </div>
        </div>

<br />
      <nav
        className={
          "navbar navbar-expand-lg navbar-light navbar-fixed  bootsnav on no-full" +
          (fixed || type === "dark" ? "" : "navbar-transparent")
        }
      >
        <SearchOverlay show={show} onClick={hideSearchForm} />

        <div className="container">

    
          <button
            type="button"
            className={"navbar-toggler " + (collapse ? "collapsed" : "")}
            data-toggle="dropdown"
            data-target="#navbar-menu"
            onClick={showMenu}
          >
            <Icofont icon="navigation-menu" />
          </button>
          <MainLogo showMenu={showMenu} />
          <DropdownMenu />
          <AttributeNav>
            <ul className="custom-search">
              <SearchItem showSearchForm={showSearchForm} />
            </ul>
          </AttributeNav>
        </div>
      </nav>
    </>
  );
};

export default HeaderOne;
