import React, { forwardRef } from "react";
import Icofont from "react-icofont";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dataTabs from "../../data/TabsIconSection/tabs-default-data.json";
import img from "../../assets/images/architecture-img.jpg";

const AboutArchitecture = forwardRef((props, ref) => (
  <section id="about" className="pb-80" ref={ref}>
    <div className="container abouttop">
      <div className="row">
        <div className="col-md-5 mb-20">
          <img className="img-fluid" src={img} alt="" />
        </div>
        <div className="col-md-7">
          <h2 className="text-uppercase font-700 mt-0">
            <span className="font-100">We are</span> Olshan Realty
          </h2>
          <p className="mt-20">Moving to Manhattan, but don't know what kind of apartment you should look for? Should it be a charming prewar, a snazzy new condo, or a cozy brownstone? How about a spectacular loft? And where do you want to live - near work, in a happening area, or in a quiet family neighborhood? And what can you afford?</p>
          
          <p className="mt-20"><h5>Don't worry, we're here with the answers.</h5></p>

          
          <Tabs className="light-tabs mt-50">
            <TabList className="nav nav-tabs text-left">
              {dataTabs
                .filter((v, i) => i < 3)
                .map((tab) => (
                  <Tab key={tab.id} className="nav-item react-tabs__tab">
                    <span>{tab.title}</span>
                  </Tab>
                ))}
            </TabList>
            <div className="tab-content text-left">
              {dataTabs
                .filter((v, i) => i < 3)
                .map((tab) => (
                  <TabPanel key={tab.id} className="tab-pane show in active">
                    <p
                      data-aos-delay={100}
                      data-aos={"fade-in"}
                    >
                      {tab.text}
                    </p>
                  </TabPanel>
                ))}
            </div>
          </Tabs>
          {/* <div className="light-tabs mt-50">
            <ul className="nav nav-tabs text-left" role="tablist">
              <li role="presentation" className="active">
                <a href="#mission" role="tab" data-toggle="tab">
                  Mission
                </a>
              </li>
              <li role="presentation">
                <a href="#history" role="tab" data-toggle="tab">
                  History
                </a>
              </li>
              <li role="presentation">
                <a href="#vision" role="tab" data-toggle="tab">
                  Vision
                </a>
              </li>
            </ul>
            <div className="tab-content text-left">
              <div
                role="tabpanel"
                className="tab-pane fade in active"
                id="mission"
              >
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  tincidunt consequat nunc, ultrices vehicula mauris mollis et.
                  Sed pharetra ultrices quam in laoreet. Donec laoreet eu mi nec
                  sagittis. Curabitur nunc velit, egestas vel aliquet ac, tempus
                  quis dolor.
                </p>
              </div>
              <div role="tabpanel" className="tab-pane fade" id="history">
                <p>
                  Olshan Realty Inc., was founded in 1980, and is one of New York City's leading boutique residential brokerage firms, specializing in residential sales, rentals, corporate relocation, investments, and property management. The company has been a marketing trendsetter by deploying social media and technology. Olshan Realty also was the first company in Manhattan to have a corporate relocation department, and has represented Fortune 500 relocating executives from Sony, Verizon, Mercedes Benz, DuPont, Time-Warner, Mobil, Exxon, J.C. Penney, W.R. Grace, Citicorp, Goldman Sachs, Credit-Suisse and Deutsche Bank among others.
                </p>
              </div>
              <div role="tabpanel" className="tab-pane fade" id="vision">
                <p>
                  This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets.
                  
                </p>
              </div>
            </div>
          </div> */}
           <a href="market-report" className="btn btn-color btn-circle btn-animate mt-0">
            <span>
              READ THIS WEEK'S LUXURY REPORT  <Icofont icon="simple-right" />
            </span>
          </a>
        </div>
      </div>
    </div>
  </section>
));

export default AboutArchitecture;
