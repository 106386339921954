import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";



import CTAOne from "../../components/CTA/CTAOne";




const Architecture = () => {
  const home = useRef();
  const about = useRef();
  const sales = useRef();
  const service = useRef();
  const agents = useRef(); 
  const rentals = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
          break;
      case "sales":
        sales.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "agents":
        agents.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "rentals":
        rentals.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne  />
      <section className=" mt-minus-50">
        <div className="container mt-minus-50">
      <div className="row mt-minus-50">
        <HeadingSection
       
          classAppend="dark-color"
        > <h2>OUR SERVICES</h2></HeadingSection>
      </div>
     
     <h2>CORPORATE RELOCATION</h2><br />

<h3>RELOCATING TO NEW YORK</h3>
<p>Moving to Manhattan, but don't know what kind of apartment you should look for? Should it be a charming prewar, a snazzy new condo, or a cozy brownstone? How about a spectacular loft? And where do you want to live - near work, in a happening area, or in a quiet family neighborhood? And what can you afford?</p>

<p>Don't worry, we're here with the answers.</p>


<h3>APARTMENTS: COOP vs CONDO</h3>
<p>There are primarily two kinds of apartments for sale in Manhattan, coop and condos. The vast majority (over 80%) are coops, while the rest are condos. They have different advantages, and you should choose the best one for you.</p>

<h3>I. Coops</h3>

<p>With a cooperative apartment you actually own shares in a corporation which gives you a proprietary lease to the apartment. Your monthly maintenance charge pays all costs to run the building, your real estate taxes, heat, water - everything but electric. Owning a coop can be a little restrictive: you must be interviewed and approved to purchase, any alterations must be approved by the board, as must any sublet and resale. They may or may not take pets. There are usually financing restrictions as well; typically a coop will not let you finance more than 75%.</p>

<p>The plusses are generally a lower price than a condo, and control over building tenancy.</p>

<h3>II. Condos</h3>

<p>A condominium is a fee type of ownership, and you actually own the four walls of the apartment as well as pay your own real estate taxes. Condos are very flexible: you can buy them in any name you want (i.e. corporate ownership, etc.), there is a less intrusive application process and rarely an interview. The only way a condo can reject a sublet or sale is to exercise their right of first refusal by matching the sale or rental price.</p>

<p>Condos in New York also tend to be newer luxury highrises, with fancy finishings such as marble baths, health clubs, and roof gardens. So if you want something fancy and new, condo is the way to go. However they tend to be considerably more expensive than coops (30% or more for equivalent size units.)</p>

<h3>III. Single Family Housing - Townhouses and Brownstones</h3>

<p>Large individual townhouses do exist in Manhattan. They are generally 3-4 stories high and 17-22 feet wide. A typical townhouse is anywhere from 3000-6000 square feet, and will often have a private garden. Some are single family homes, and some have rental apartments in them that can generate income for the owner. Prices start at about $3,000,000 and go upward from there. Yearly real estate taxes average 1% of the purchase price.</p>


    </div>
    </section>
    <GalleryArchitecture
        title="MORE FEATURED PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-150"
        ref={sales}
      />

      <ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />

<CTAOne
        tagline="Olshan Luxury Report"
        title="Highly focused. Driven by Data."
        textButton="GET THE LATEST LUXURY REPORT"
        textLink="marketreport"
        bg="color"
      >
        This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets. 
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};



export default Architecture;
