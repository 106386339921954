import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";



import CTAOne from "../../components/CTA/CTAOne";




const Architecture = () => {
  const home = useRef();
  const about = useRef();
  const sales = useRef();
  const service = useRef();
  const agents = useRef(); 
  const rentals = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
          break;
      case "sales":
        sales.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "agents":
        agents.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "rentals":
        rentals.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne  />
      <section className=" mt-minus-50">
        <div className="container mt-minus-50">
      <div className="row mt-minus-50">
        <HeadingSection
       
          classAppend="dark-color"
        > <h2>OUR SERVICES</h2></HeadingSection>
      </div>
     
     <h2>INTERNATIONAL SERVICES</h2><br />


<p>Olshan Realty is pleased to announce its affiliation with Carter Jonas, one of England's leading real estate brokerage firms of quality homes in both town and country. With Carter Jonas, Olshan will be able to help its customers purchase in the UK and abroad. Carter Jonas has real estate agency, lettings/management teams and building surveyors across the country and is known for its superb service. It has a network of offices is complemented by six independent estate agencies in 17 locations across the UK, forming Carter Jonas London Link.
Their complete residential services include: valuations and appraisals; purchasing advice; sales and purchases; new home sales; country house sales; town house sales; residential lettings; building surveys; development; property investment and management.</p>

<h5>For More Information about Carter Jonas, our International Relocation Services and London real estate, contact:</h5>

<h4>Donna Olshan</h4>
Olshan Realty Inc.<br />
641 Lexington Avenue<br />
New York N.Y., 10022<br />
212-751-3300x110<br />
<a href="MAILTO:dso@olshan.com">dso@olshan.com</a>


    </div>
    </section>
    <GalleryArchitecture
        title="MORE FEATURED PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-150"
        ref={sales}
      />

      <ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />

<CTAOne
        tagline="Olshan Luxury Report"
        title="Highly focused. Driven by Data."
        textButton="GET THE LATEST LUXURY REPORT"
        textLink="marketreport"
        bg="color"
      >
        This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets. 
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};



export default Architecture;
