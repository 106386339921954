import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";



import CTAOne from "../../components/CTA/CTAOne";




const Architecture = () => {
  const home = useRef();
  const about = useRef();
  const sales = useRef();
  const service = useRef();
  const agents = useRef(); 
  const rentals = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
          break;
      case "sales":
        sales.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "agents":
        agents.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "rentals":
        rentals.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne  />
      <section className=" mt-minus-50">
        <div className="container mt-minus-50">
      <div className="row mt-minus-50">
        <HeadingSection
       
          classAppend="dark-color"
        > <h2>OUR SERVICES</h2></HeadingSection>
      </div>
     
     <h2>OLSHAN ESTATE SOLUTIONS</h2><br />


<p><strong>Olshan Estate Solutions</strong> offers specialized expertise to executors and estate attorneys who need to maximize the sale price of estate homes and apartments in the quickest, most-stress free way possible. We oversee all phases of preparation and sale, making Olshan the perfect teammate to assist in the difficult time of estate liquidation.</p>

<p><h5>Typical services provided for our Estate Sale Solution properties include the following:</h5>
<ul>
<li><strong>Securing the property</strong>, rekeying, turning on utilities, arranging for HOA payments</li>
<li><strong>Estate Liquidation -</strong> Furniture/Personal Property Move Out/Donations/Trashouts</li>
<li><strong>Property Evaluation/Broker Price Opinion</strong> (BPO)</li>
<li><strong>Fix Up and Stage</strong> to Maximize Sale Price and minimize carrying costs</li>
<li><strong>Sale of Home</strong> to Qualified Buyer</li>
<li><strong>Obtaining Coop/Condo Board Approval</strong></li>
</ul></p>

<p>Our vetted team of contractors can quickly give a tired apartment a "facelift", and professionally stage it with existing furniture, or go for a newer, modern take to ensure the highest sale price for the estate.</p>

<p>Each Olshan Estate agent has over 20 years experience in the sales and property management industry, and will be your personal point of contact to oversee the preparation and sale of the home. We can assist homeowners in Manhattan, Brooklyn, and Southern Westchester.</p>

<p>For more information, contact Donna Olshan at 212-751-3300 x110, or via email at <a href="MAILTO:dso@olshan.com">dso@olshan.com</a>. All inquiries will be kept confidential.</p>


    </div>
    </section>
    <GalleryArchitecture
        title="MORE FEATURED PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-150"
        ref={sales}
      />

      <ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />

<CTAOne
        tagline="Olshan Luxury Report"
        title="Highly focused. Driven by Data."
        textButton="GET THE LATEST LUXURY REPORT"
        textLink="marketreport"
        bg="color"
      >
        This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets. 
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};



export default Architecture;
