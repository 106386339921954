import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import AboutArchitecture from "../../components/AboutUs/AboutArchitecture";
import FunFactsArchitecture from "../../components/FunFacts/FunFactsArchitecture";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";

import CTAOne from "../../components/CTA/CTAOne";




const Architecture = () => {
  const home = useRef();
  const about = useRef();
  const sales = useRef();
  const service = useRef();
  const agents = useRef(); 
  const rentals = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
          break;
      case "sales":
        sales.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "agents":
        agents.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "rentals":
        rentals.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne scrollToSection={scrollToSection} />

      <AboutArchitecture   ref={about} classAppend="mt-50" />

      <section className=" mt-minus-50">
        <div className="container mt-0 col-md-12">

<h3>SALES FORCE</h3>
Olshan Realty is a licensed real estate brokerage firm, serving New York City, specializing in the sale of co-ops and condos, townhouses, lofts and rentals. Salespersons are equipped to handle all areas, price ranges, and needs. Many agents live and work in the neighborhoods they serve, and are closely involved in their communities. They know about available apartments, neighborhood characteristics, schools and shopping. They can help guide the client thru the purchase, mortgage, co-op and condo application process. All agents are members of The Real Estate Board of New York.

<h3>CORPORATE RELOCATION DEPARTMENT</h3>
When it comes to relocating executives, companies often turn to corporate relocation experts to help buy and sell residential real estate for their employees. Olshan Realty Inc. has the premier relocation department and the top choice of America's relocation companies. Since 1980, Olshan has handled hundreds of millions of dollars in transactions on behalf of corporate America. Olshan has represented Cartus, Brookfield Global Relocation, Prudential Relocation, Relocation Resources, Mobility Services International, Associates Relocation and Royal LePage among others. Our full-time staff is equipped to handle brokerage, rental, property management and home finding assistance. Olshan serves customers in all price ranges. The company's database accesses all the many thousands of sales and rental listing across New York. It also is the exclusive broker for many rentals being handled by Olshan Realty's Property Management division.

<p>Olshan serves customers in all price ranges. The company's database accesses all the many thousands of sales and rental listing across New York. It also is the exclusive broker for many rentals being handled by Olshan Realty's Property Management division.</p>

<h3>INVESTMENT AND WEALTH MANAGEMENT SERVICES</h3>
New York City has proven a superb place to invest in real estate. Many clients are looking for wealth diversification by investing in a portfolio of New York real estate. Olshan Realty routinely assists investors looking to maximize returns. The company is highly experienced in 1031 exchanges, and advises on bulk purchases that can be resold or rented. Olshan offers property management services for these investments.

<h3>MARKETING DEPARTMENT - BEING DIGITAL</h3>
<ul>
    <li>Being Digital 24/7: It's all about the Web. Today's buyer wants pictures, floorplans, 24/7 information. Olshan's marketing department helps create a strategic plan to sell or rent a property.</li>

    <li>Google Search Optimization: Type in your building on the web and see where it appears. Olshan pays attention to pushing its exclusive properties to the top of the search engine rankings.</li>

    <li>Olshan.Com—Website and Domain Strategy: Since 1995, when Donna Olshan saw the power of the internet, the company has been an active purchaser of prime Internet real estate in the form of domain names which drive traffic to Olshan.com and its exclusive listings. Olshan Realty owns names the following names: www.townhouse.com, www.co-ops.com, www.nyrealestate.com, www.cityhomes.com, www.nybroker.com, www.nyclofts.com, www.nyhomes.com among 80 other top real estate domain names.</li>

    <li>Social Media Strategies: The consumer is constantly changing the way they research and purchase real estate. Olshan Realty employs Facebook and blogs to keep their properties in the Social Media conversation.</li>

    <li>Direct Mail: Olshan also uses traditional media to publicize it properties by using direct mail to interest neighbors and friends.</li>

    <li>Staging: Presentation of a property is critical. The sales price is greatly influenced by how the property is presented or staged. Olshan will provide recommendations to help best present a property and maximize market value. Often this involves hiring a stager or just helping you edit the clutter and reposition the furniture.</li>
</ul>

<h3>OLSHAN LUXURY REPORT</h3>
This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets.

<h3>FOREIGN LANGUAGE</h3>
Olshan has agents who speak Spanish.

</div>
</section>
      
      <FunFactsArchitecture title="2022" tagline="By The Numbers" >
      The 2021 sales results were astonishing by any measure: 1,877 contracts were signed at $4 million and above, totaling $15,912,375,308--a record-shattering performance going back to 2006, when we launched this report. It is almost three times the number of deals done in 2020 and twice as many as 2019. What’s more, the totals for both signed contracts and volume are nearly 30% higher than the highest years of 2013-2015. 

      Here is how 2022 is shaping up so far:
      </ FunFactsArchitecture>
      

     

      <GalleryArchitecture
        title="MORE FEATURED PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-150"
        ref={sales}
      />

    

      <ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />

<CTAOne
        tagline="Olshan Luxury Report"
        title="Highly focused. Driven by Data."
        textButton="GET THE LATEST LUXURY REPORT"
        textLink="marketreport"
        bg="color"
      >
        This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets. 
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};



export default Architecture;
