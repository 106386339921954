import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";



import CTAOne from "../../components/CTA/CTAOne";




const Architecture = () => {
  const home = useRef();
  const about = useRef();
  const sales = useRef();
  const service = useRef();
  const agents = useRef(); 
  const rentals = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
          break;
      case "sales":
        sales.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "agents":
        agents.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "rentals":
        rentals.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne  />
      <section className=" mt-minus-50">
        <div className="container mt-minus-50">
      <div className="row mt-minus-50">
        <HeadingSection
       
          classAppend="dark-color"
        > <h2>OUR SERVICES</h2></HeadingSection>
      </div>
     
     <h2>PROPERTY &amp; INVESTMENT MANAGEMENT</h2><br />

<h3>New York City's Rental and Property Management Specialists</h3>

<p>For over 25 years, Olshan Realty has been New York City's leading relocation broker. Olshan, a 5 Star Award/Circle of Excellence winner, has been the Cartus agent in Manhattan since 1981. During that time we have successfully overseen thousands of corporate relocations and home sales, and have been instrumental in group moves for such firms as Mobil, JC Penney, and Citicorp.</p>

<p>By special request, we assumed responsibilities of rental property management for our corporate clients in 1994. Taking over from a firm that had neglected all management for months, Olshan quickly established order. In fact, some of those same rentals are still with us today. Olshan's property management division has continued to grow. We oversee both corporate and individual properties, and have successfully coordinated incoming group moves from 1 to 60 renters, for such firms as Sony and Verizon.</p>

<h3>GREAT NEWS FOR INVESTORS!</h3>

<p>Individual investors can now take advantage of Olshan's Fortune 500 corporate level service for themselves! Our full service team provides superior one-stop service: we help investors identify, purchase, rent, and/or manage their Manhattan Real Estate investment portfolio.
</p>

<p>New York City condos have soundly outperformed the stock market for over a decade, offering strong double digit capital appreciation while obtaining high rents. This makes owning a Manhattan home the perfect choice for savvy investors seeking to diversify their Wealth Management portfolios, as well as foreign buyers taking advantage of the currency market.</p>

<h3>Services -- Property Management</h3>

<p>Typical services provided for our managed properties include the following:</p>
<ul>
<li>Property evaluation/Broker Price Opinion (BPO)</li>
<li>Apartment prep (painting/floors/cleaning)</li>
<li>Co-brokering the rental listing</li>
<li>Finding a qualified tenant</li>
<li>Preparing leases</li>
<li>Obtaining coop/condo board approval</li>
<li>Establishing the tenant in the apartment</li>
<li>Providing lease extensions as necessary</li>
<li>Day-to-day tenant relations</li>
<li>Accounting and billing</li>
<li>Performing quarterly inspections</li>
<li>Overseeing all repairs and requests</li>
</ul>

<p>These tasks are performed for a monthly fee per unit. Our staff is available 24 hours a day, via voice mail, and is quick to provide same day service to all emergencies.</p>


    </div>
    </section>
    <GalleryArchitecture
        title="MORE FEATURED PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-150"
        ref={sales}
      />

      <ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />

<CTAOne
        tagline="Olshan Luxury Report"
        title="Highly focused. Driven by Data."
        textButton="GET THE LATEST LUXURY REPORT"
        textLink="marketreport"
        bg="color"
      >
        This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets. 
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};



export default Architecture;
