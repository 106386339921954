import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import dataAccordion from "../../data/Accordion/accordions-rentalsfaq-data.json";
import Loader from "../../components/Loader/Loader";
import AccordionsComponentFAQ from "../../components/Accordions/AccordionsComponentFAQ";
import HeaderOne from "../../components/Header/HeaderOne";
import PageTitleWidget from "../../components/PageTitle/PageTitleWidget";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";
import CTAOne from "../../components/CTA/CTAOne";



const Architecture = () => {
    const home = useRef();
    const about = useRef();
    const sales = useRef();
    const service = useRef();
    const agents = useRef(); 
    const rentals = useRef();
    const contact = useRef();
  
    const scrollToSection = (e, content) => {
      e.preventDefault();
      switch (content) {
        case "home":
          home.current.scrollIntoView({ behavior: "smooth" });
          break;
        case "about":
          about.current.scrollIntoView({ behavior: "smooth" });
            break;
        case "sales":
          sales.current.scrollIntoView({ behavior: "smooth" });
          break;
        case "service":
          service.current.scrollIntoView({ behavior: "smooth" });
          break;
        case "agents":
          agents.current.scrollIntoView({ behavior: "smooth" });
          break;
        case "rentals":
          rentals.current.scrollIntoView({ behavior: "smooth" });
          break;
        case "contact":
          contact.current.scrollIntoView({ behavior: "smooth" });
          break;
        default:
      }
    };
  
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);

    return (
    
  <Loader>
    <HeaderOne />
    <PageTitleWidget title="RENTALS FAQs"/>
    <section>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <AccordionsComponentFAQ type="color" data={dataAccordion} />
          </div>
          <div className="col-sm-4">
            <form className="search-form" method="get">
              <input
                type="text"
                name="name"
                className="form-control search-field"
                id="search"
                placeholder="Search by Address or Zip Code"
              />
              <button
                type="submit"
                className="icofont icofont-search-1 search-submit"
              ></button>
            </form>
            <div className="banner-box help-bg mt-30">
              <div className="red-overlay-bg"></div>
              <div className="relative white-color text-center">
                <h4 className="text-uppercase">We're Here to Help!</h4>
                <p>
                  We're friendly and available to chat. Reach out to us anytime
                  and we'll happily answer your questions.
                </p>
                <a href="!#" className="btn btn-outline-white btn-square mt-10">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <GalleryArchitecture
        title="FEATURED RENTAL PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-150"
        ref={sales}
      />

<ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />

<CTAOne
        tagline="Olshan Luxury Report"
        title="Highly focused. Driven by Data."
        textButton="GET THE LATEST LUXURY REPORT"
        textLink="marketreport"
        bg="color"
      >
        This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets. 
      </CTAOne>
    <FooterOne />
  </Loader>
);
};


export default Architecture;
