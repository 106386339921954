import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Home pages
import CreativeAgency from "./pages/home/CreativeAgency";
import SmallBusiness from "./pages/home/SmallBusiness";
import CorporateBusiness from "./pages/home/CorporateBusiness";
import StartupBusiness from "./pages/home/StartupBusiness";
import MinimalDesign from "./pages/home/MinimalDesign";
import CreativeOnePage from "./pages/home/CreativeOnePage";
import CreativePortfolio from "./pages/home/CreativePortfolio";
import PersonalResume from "./pages/home/PersonalResume";
import SEOAgency from "./pages/home/SEOAgency";
import DigitalAgency from "./pages/home/DigitalAgency";
import About from "./pages/home/About";
import Agents from "./pages/home/Agents";
import SalesFaq from "./pages/home/SalesFaq";
import RentalsFaq from "./pages/home/RentalsFaq";
import Sales from "./pages/home/Sales";
import Rentals from "./pages/home/Rentals";
import Property from "./pages/home/Property";
import EstateSolutions from "./pages/home/EstateSolutions";
import International from "./pages/home/International";
import PropertyManagement from "./pages/home/PropertyManagement";
import CorporateRelocation from "./pages/home/CorporateRelocation";
import Architecture from "./pages/home/Architecture";
import Restaurant from "./pages/home/Restaurant";
import OnlineShop from "./pages/home/OnlineShop";
import Photography from "./pages/home/Photography";
import GymFitness from "./pages/home/GymFitness";
import AppStyle from "./pages/home/AppStyle";
import TravelAgency from "./pages/home/TravelAgency";
import Construction from "./pages/home/Construction";
import Music from "./pages/home/Music";
import Weddings from "./pages/home/Weddings";
import DesignStudio from "./pages/home/DesignStudio";

// Pages
import AboutUs from "./pages/about/AboutUs";
import AboutCreative from "./pages/about/AboutCreative";
import ServiceSimple from "./pages/services/ServiceSimple";
import ServiceCreative from "./pages/services/ServiceCreative";
import ContactSimple from "./pages/contact/ContactSimple";
import ContactCreative from "./pages/contact/ContactCreative";
import Page404 from "./pages/others/Page404";
import ComingSoon from "./pages/others/ComingSoon";
import FAQs from "./pages/others/FAQs";
import TermCondition from "./pages/others/TermCondition";
import Privacy from "./pages/others/Privacy";
import Career from "./pages/others/Career";
import Login from "./pages/others/Login";

// Blog
import BlogStandard from "./pages/blog/BlogStandard";
import BlogGrid from "./pages/blog/BlogGrid";
import BlogSidebar from "./pages/blog/BlogSidebar";
import BlogMasonry from "./pages/blog/BlogMasonry";
import BlogCarousel from "./pages/blog/BlogCarousel";
import BlogSidebarRight from "./pages/blog/BlogSidebarRight";
import BlogSidebarLeft from "./pages/blog/BlogSidebarLeft";

// Portfolio
import PortfolioBoxedTwo from "./pages/portfolio/PortfolioBoxedTwo";
import PortfolioBoxedThree from "./pages/portfolio/PortfolioBoxedThree";
import PortfolioBoxedFour from "./pages/portfolio/PortfolioBoxedFour";
import PortfolioBoxedSpaceTwo from "./pages/portfolio/PortfolioBoxedSpaceTwo";
import PortfolioBoxedSpaceThree from "./pages/portfolio/PortfolioBoxedSpaceThree";
import PortfolioBoxedSpaceFour from "./pages/portfolio/PortfolioBoxedSpaceFour";
import PortfolioWideTwo from "./pages/portfolio/PortfolioWideTwo";
import PortfolioWideThree from "./pages/portfolio/PortfolioWideThree";
import PortfolioWideFour from "./pages/portfolio/PortfolioWideFour";
import PortfolioWideSpaceTwo from "./pages/portfolio/PortfolioWideSpaceTwo";
import PortfolioWideSpaceThree from "./pages/portfolio/PortfolioWideSpaceThree";
import PortfolioWideSpaceFour from "./pages/portfolio/PortfolioWideSpaceFour";


// Shops
import ShopFullwidth from "./pages/shops/ShopFullwidth";
import ShopStandard from "./pages/shops/ShopStandard";
import ShopBoxed from "./pages/shops/ShopBoxed";
import ShopMasonry from "./pages/shops/ShopMasonry";
import ShopSingleProduct from "./pages/shops/ShopSingleProduct";
import ShopCart from "./pages/shops/ShopCart";
import ShopCheckout from "./pages/shops/ShopCheckout";

// Elements
import Buttons from "./pages/elements/Buttons";
import Typography from "./pages/elements/Typography";
import Alerts from "./pages/elements/Alerts";
import PricingTables from "./pages/elements/PricingTables";
import CallToActions from "./pages/elements/CallToActions";
import ParallaxSections from "./pages/elements/ParallaxSections";
import Tabs from "./pages/elements/Tabs";
import Accordions from "./pages/elements/Accordions";
import Sliders from "./pages/elements/Sliders";
import ProgressBars from "./pages/elements/ProgressBars";
import ContactForms from "./pages/elements/ContactForms";
import GoogleMaps from "./pages/elements/GoogleMaps";
import ProgressBarRing from "./pages/elements/ProgressBarRing";
import ThemeIcons from "./pages/elements/ThemeIcons";
import BoxedIcons from "./pages/elements/BoxedIcons";
import GridColumns from "./pages/elements/GridColumns";
import TeamMembers from "./pages/elements/TeamMembers";
import Countdowns from "./pages/elements/Countdowns";
import ScrollToTop from "./helpers/ScrollToTop";
import Demos from "./pages/Demos";

class DebugRouter extends Router {
  constructor(props){
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null,2))
    this.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

function App() {
  console.log("href", window.location.href, "ENV", process.env.PUBLIC_URL);
  console.log("location",JSON.stringify(window.location,null,2))
  
  return (
    <DebugRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path={`/`} component={Architecture} />
          <Route
            exact
            path={`/creative-agency`}
            component={CreativeAgency}
          />
          <Route
            exact
            path={`/small-business`}
            component={SmallBusiness}
          />
          <Route
            exact
            path={`/corporate-business`}
            component={CorporateBusiness}
          />
          <Route
            exact
            path={`/startup-business`}
            component={StartupBusiness}
          />
          <Route
            exact
            path={`/minimal-design`}
            component={MinimalDesign}
          />
          <Route
            exact
            path={`/creative-onepage`}
            component={CreativeOnePage}
          />
          <Route
            exact
            path={`/creative-portfolio`}
            component={CreativePortfolio}
          />
          <Route
            exact
            path={`/personal-resume`}
            component={PersonalResume}
          />
          <Route
            exact
            path={`/seo-agency`}
            component={SEOAgency}
          />
          <Route
            exact
            path={`/digital-agency`}
            component={DigitalAgency}
          />
          <Route
            exact
            path={`/home`}
            component={Architecture}
          />
          <Route
            exact
            path={`/rentals-faq`}
            component={RentalsFaq}
          />
          <Route
            exact
            path={`/sales-faq`}
            component={SalesFaq}
          />
          <Route
            exact
            path={`/agents`}
            component={Agents}
          />
          <Route
          exact
          path={`/sales`}
          component={Sales}
        />
          <Route
            exact
            path={`/rentals`}
            component={Rentals}
          />
          <Route
            exact
            path={`/about`}
            component={About}
          />
          <Route
            exact
            path={`/estate-solutions`}
            component={EstateSolutions}
          />
          <Route
            exact
            path={`/property-management`}
            component={PropertyManagement}
          />
          <Route
            exact
            path={`/international`}
            component={International}
          />
          <Route
            exact
            path={`/corporate-relocation`}
            component={CorporateRelocation}
          />
          <Route
            exact
            path={`/restaurant`}
            component={Restaurant}
          />
          <Route
            exact
            path={`/online-shop`}
            component={OnlineShop}
          />
          <Route
            exact
            path={`/photography`}
            component={Photography}
          />
          <Route
            exact
            path={`/gym-fitness`}
            component={GymFitness}
          />
          <Route
            exact
            path={`/app-style`}
            component={AppStyle}
          />
          <Route
            exact
            path={`/travel-agency`}
            component={TravelAgency}
          />
          <Route
            exact
            path={`/construction`}
            component={Construction}
          />
          <Route
            exact
            path={`/music`}
            component={Music}
          />
          <Route
            exact
            path={`/weddings`}
            component={Weddings}
          />
          <Route
            exact
            path={`/design-studio`}
            component={DesignStudio}
          />
          <Route
            exact
            path={`/about-us`}
            component={AboutUs}
          />
          <Route
            exact
            path={`/about-creative`}
            component={AboutCreative}
          />
          <Route
            exact
            path={`/service-simple`}
            component={ServiceSimple}
          />
          <Route
            exact
            path={`/service-creative`}
            component={ServiceCreative}
          />
          <Route
            exact
            path={`/contact-simple`}
            component={ContactSimple}
          />
          <Route
            exact
            path={`/contact-creative`}
            component={ContactCreative}
          />
          <Route
            exact
            path={`/404-page`}
            component={Page404}
          />
          <Route
            exact
            path={`/coming-soon`}
            component={ComingSoon}
          />
          <Route
            exact
            path={`/faqs`}
            component={FAQs}
          />
          <Route
            exact
            path={`/term-condition`}
            component={TermCondition}
          />
          <Route
            exact
            path={`/privacy`}
            component={Privacy}
          />
          <Route
            exact
            path={`/career`}
            component={Career}
          />
          <Route
            exact
            path={`/login`}
            component={Login}
          />
          <Route
            exact
            path={`/blog/:blogID`}
            component={BlogStandard}
          />
          <Route
            exact
            path={`/blog-grid`}
            component={BlogGrid}
          />
          <Route
            exact
            path={`/blog-sidebar`}
            component={BlogSidebar}
          />
          <Route
            exact
            path={`/blog-masonry`}
            component={BlogMasonry}
          />
          <Route
            exact
            path={`/blog-carousel`}
            component={BlogCarousel}
          />
          <Route
            exact
            path={`/blog-sidebar-right`}
            component={BlogSidebarRight}
          />
          <Route
            exact
            path={`/blog-sidebar-left`}
            component={BlogSidebarLeft}
          />
          <Route
            exact
            path={`/box-2-columns`}
            component={PortfolioBoxedTwo}
          />
          <Route
            exact
            path={`/box-3-columns`}
            component={PortfolioBoxedThree}
          />
          <Route
            exact
            path={`/box-4-columns`}
            component={PortfolioBoxedFour}
          />
          <Route
            exact
            path={`/box-space-2-columns`}
            component={PortfolioBoxedSpaceTwo}
          />
          <Route
            exact
            path={`/box-space-3-columns`}
            component={PortfolioBoxedSpaceThree}
          />
          <Route
            exact
            path={`/box-space-4-columns`}
            component={PortfolioBoxedSpaceFour}
          />
          <Route
            exact
            path={`/wide-2-columns`}
            component={PortfolioWideTwo}
          />
          <Route
            exact
            path={`/wide-3-columns`}
            component={PortfolioWideThree}
          />
          <Route
            exact
            path={`/wide-4-columns`}
            component={PortfolioWideFour}
          />
          <Route
            exact
            path={`/wide-space-2-columns`}
            component={PortfolioWideSpaceTwo}
          />
          <Route
            exact
            path={`/wide-space-3-columns`}
            component={PortfolioWideSpaceThree}
          />
          <Route
            exact
            path={`/wide-space-4-columns`}
            component={PortfolioWideSpaceFour}
          />
          <Route
            exact
            path={`/sale-property`}
            component={Property}
          />
          <Route
            exact
            path={`/shop-fullwidth`}
            component={ShopFullwidth}
          />
          <Route
            exact
            path={`/shop-standard`}
            component={ShopStandard}
          />
          <Route
            exact
            path={`/shop-boxed`}
            component={ShopBoxed}
          />
          <Route
            exact
            path={`/shop-masonry`}
            component={ShopMasonry}
          />
          <Route
            exact
            path={`/shop-product`}
            component={ShopSingleProduct}
          />
          <Route
            exact
            path={`/shop-cart`}
            component={ShopCart}
          />
          <Route
            exact
            path={`/shop-checkout`}
            component={ShopCheckout}
          />
          <Route
            exact
            path={`/buttons`}
            component={Buttons}
          />
          <Route
            exact
            path={`/typography`}
            component={Typography}
          />
          <Route
            exact
            path={`/alerts`}
            component={Alerts}
          />
          <Route
            exact
            path={`/pricing-tables`}
            component={PricingTables}
          />
          <Route
            exact
            path={`/call-to-action`}
            component={CallToActions}
          />
          <Route
            exact
            path={`/parallax-section`}
            component={ParallaxSections}
          />
          <Route
            exact
            path={`/tabs`}
            component={Tabs}
          />
          <Route
            exact
            path={`/accordions`}
            component={Accordions}
          />
          <Route
            exact
            path={`/sliders`}
            component={Sliders}
          />
          <Route
            exact
            path={`/progress-bar`}
            component={ProgressBars}
          />
          <Route
            exact
            path={`/contact-form`}
            component={ContactForms}
          />
          <Route
            exact
            path={`/google-maps`}
            component={GoogleMaps}
          />
          <Route
            exact
            path={`/progress-bar-ring`}
            component={ProgressBarRing}
          />
          <Route
            exact
            path={`/theme-icons`}
            component={ThemeIcons}
          />
          <Route
            exact
            path={`/boxed-icons`}
            component={BoxedIcons}
          />
          <Route
            exact
            path={`/grid-columns`}
            component={GridColumns}
          />
          <Route
            exact
            path={`/team-member`}
            component={TeamMembers}
          />
          <Route
            exact
            path={`/countdowns`}
            component={Countdowns}
          />
          <Route component={Page404} />
        </Switch>
      </ScrollToTop>
    </DebugRouter>
  );
}

export default App;
