import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Swiper from "react-id-swiper";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import img1 from "../../assets/images/properties/19040/large1.jpg";
import img2 from "../../assets/images/properties/19040/large2.jpg";
import img3 from "../../assets/images/properties/19040/large3.jpg";
import img4 from "../../assets/images/properties/19040/large4.jpg";
import img5 from "../../assets/images/properties/19040/large5.jpg";
import img6 from "../../assets/images/properties/19040/large6.jpg";
import img7 from "../../assets/images/properties/19040/large0.jpg";
import img8 from "../../assets/images/walkscore.png";
import img9 from "../../assets/images/google_map.png";
import img10 from "../../assets/images/transit.png";
import img11 from "../../assets/images/traveltime.png";
import img12 from "../../assets/images/schools.png";
import Icofont from "react-icofont";
import CTAOne from "../../components/CTA/CTAOne";
import FooterOne from "../../components/Footer/FooterOne";
import MortgageCalculator from "mortgage-calculator-react";
import customStyle from "../../assets/css/CustomStyle.css";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";



const SinglePortfolio = () => {
  const params = {
    navigation: {
      nextEl: ".portfolio-slider-next",
      prevEl: ".portfolio-slider-prev",
    },
    effect: "fade",
    autoplay: {
      delay: 2500,
    },
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne />
      <section className="pt-100 pt-100 mt-50">
        <div className="container">

          
          <div className="row">

            
            <div className="col-md-9 portfolio-left">

            
              <div className="portfolio-slider">
                <Swiper {...params}>
                  <div className="item">
                    <img className="img-fluid" src={img1} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={img2} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={img3} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={img4} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={img5} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={img6} alt="" />
                  </div>
                </Swiper>
                <div className="row col-md-20">
          <div className="col-md-2 mb-0 col-sm-1 icon-center-text">
          <h2 className="red-color icon-center"><Icofont icon="layout" /></h2>
            FLOORPLAN
          </div>
          <div className="col-md-2 mb-0 col-sm-1 icon-center-text">
          <h2 className="red-color icon-center"><Icofont icon="location-pin" /></h2>
            MAP
          </div>
          <div className="col-md-2 mb-0 col-sm-1 icon-center-text">
          <h2 className="red-color icon-center"><Icofont icon="calculator" /></h2>
            MORTGAGE
          </div>
          <div className="col-md-2 mb-0 col-sm-1 icon-center-text">
          <h2 className="red-color icon-center"><Icofont icon="video-alt" /></h2>
            VIRTUAL TOUR
          </div>
          <div className="col-md-2 mb-0 col-sm-1 icon-center-text">
            <h2 className="red-color icon-center"><Icofont icon="printer" /></h2>
            PRINT
          </div>
          <div className="col-md-2 mb-0 col-sm-1 icon-center-text">
            <h2 className="red-color icon-center"><Icofont icon="share" /></h2>
            SHARE
          </div>
        </div>
              </div>
              <h3>Property Description</h3>
              <p className="mt-20">
              The living room, formal dining room, master suite and 2nd bedrooms all face the water. The apartment has 10-7’ ceilings and features 2 wood burning fireplaces in the living room and master suite. Apartment 11/12F offers baronial-sized rooms with the square footage of a townhouse. This particular line has not been available in the building for almost 20 years. The apartment is in excellent condition with all the modern conveniences: a family room/library off the kitchen, a gym in the unit, a private guest suite, and a 450+ bottle wine vault. This grand duplex is a great home and a great value.
              </p>

              <h3>Building Notes</h3>
              <p className="mt-20">
              10 Gracie Square is a 15-story, 42-unit co-op that was built in 1929. It has doormen, a resident superintendent a drive-thru porte cochere, children's playroom, storage rooms, and a squash/basketball court. Nestled on the River and Carl Schurz Park, it is considered one of the most prestigious buildings in New York.
              </p>
              <div className="container" columns="2">
      

        <div className="row left-red-bar">
        <p><h4>Exclusive Agent</h4></p>
          <div className="col-md-4 mb-20 col-sm-4 text-left float-left">
     
  <img
    src={require("../../assets/images/team/donna.jpg")}
    className="img-fluid member-thumb"
    alt="Donna Olshan"
    
  /> 
</div>

          <div className="col-md-6 mb-0 col-sm-6">
            <h4>Donna Olshan</h4>
            <h5>PRESIDENT / LICENSED REAL ESTATE BROKER</h5>
            <strong>Direct Phone:</strong> 212-751-3300 x110<br />
            <strong>Residence:</strong> 212-517-6617<br />
            <strong>Fax:</strong> 212-751-3577
            <br />
          
                     <a href="mailto:dso@olshan.com">
                      dso@olshan.com
                    </a>
                  
                    <p className="mt-20">
                <a
                  href={process.env.PUBLIC_URL}
                  className="btn btn-color btn-rounded"
                >
                  More About Donna Olshan
                </a>
              </p>
          </div>

       
      </div>


</div>
<br /><hr className="full" />

<section className="pt-0 mt-0">
<div className="row">
<h3>Transit &amp; Walkability</h3>
<div className="item">
<br /><img className="img-fluid" src={img8} alt="" />
                  </div>

           <div className="col-md-6 mb-20 col-sm-6">
           <div className="item">
                    <img className="img-fluid" src={img10} alt="" />
                  </div>
          </div>
          <div className="col-md-6 mb-0 col-sm-6">
          <div className="item">
                    <img className="img-fluid" src={img11} alt="" />
                  </div>
          </div>
        </div>
  </section>

  <hr className="full" />


  <section className="pt-0 mt-0">
<div className="row">
<h3>Neighborhood Amenities</h3>


                  <div className="item">
                  <br /><img className="img-fluid" src={img9} alt="" />
                  </div>

        </div>
  </section>
  <hr className="full" />


  <section className="pt-0 mt-0">
<div className="row">
<h3>Nearby Schools</h3>
<div className="item">
<br /><img className="img-fluid" src={img12} alt="" />
</div>


        </div>
  </section>

  <hr className="full" />

  <GalleryArchitecture
        title="MORE FEATURED PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-10 pt-10"

      />

</div>


            <div className="col-md-3 portfolio-right">
            
              <h3>10 Gracie Square 11/12F</h3><h4>East End/Carl Schurz Park</h4>
              <hr className="full-red" />

              <p className="mt-10">
              <strong>This is rare beach front property--a Dramatic 10-room prewar duplex including 5 to 6 bedrooms, 4.5 bathrooms, with a 66-foot expanse over the East River. </strong>
              </p>
                  
              <hr className="full-red" />
    <h5>OFFERED AT</h5>
              <ul className="project-detail-box">
                <li>
                  <strong>Asking Price:</strong> $8,875,000
                </li>
                <li>
                  <strong>Maintenance:</strong> $8538
                </li>
                <li>
                  <strong>Financing Allowed:</strong> 30% permitted 
                </li>
                <li>
                  <strong>Flip Tax:</strong> 3%
                </li>
                <li>
                River Views
                </li>
                <li>
                Baronial-sized rooms
                </li>
                <li>
                2 wood burning fireplaces
                </li>
              </ul>

              <div className="item">
                    <img className="img-fluid floorplan-thumb" src={img7} alt="" />
                  </div>
              <br />
              <h5>FEATURES</h5>
              <ul className="project-detail-box">
                <li>
                  <strong>Room Count:</strong> 10
                </li>
                <li>
                  <strong>Bedrooms:</strong> 5 to 6
                </li>
                <li>
                  <strong>Bathrooms:</strong> 4.5
                </li>
                <li>
                  <strong>Condition:</strong> Excellent
                </li>
                <li>
                  <strong>Exposures:</strong> East, West, South
                </li>
                <li>
                Dramatic Duplex
                </li>
                <li>
                Views: River Views
                </li>
                <li>
                2 wood burning fireplaces
                </li>
                
                <li>
                Private Guest Suite
                </li>
                <li>
                18' x 30' LR with fireplace
                </li>
                <li>
                10-7' ceilings
                </li>
                <li>
                Eat-in kitchen and Family Room
                </li>
                <li>
                Gym in apartment
                </li>
              </ul>
             
              <MortgageCalculator price={8875000} downPayment={4437500} interestRate={0.035} months={120} additionalPrincipalPayment={100} taxRate={0.01} insuranceRate={0.01} mortgageInsuranceEnabled={false}  cstyles={customStyle} />


              {/* <ul className="project-type-list">
                <li>
                  <Icofont icon="diamond" /> Design
                </li>
                <li>
                  <Icofont icon="light-bulb" />
                  Development
                </li>
                <li>
                  <Icofont icon="layers" />
                  Apps
                </li>
              </ul> */}


          </div>
          </div>  </div>
      </section>
      <CTAOne
        tagline="Olshan Luxury Report"
        title="Highly focused. Driven by Data."
        textButton="GET THE LATEST LUXURY REPORT"
        textLink="marketreport"
        bg="color"
      >
        This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets. 
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};

export default SinglePortfolio;
