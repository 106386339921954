import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import dataArchitecture from "../../data/Slider/architecture-data.json";
import dataAccordion from "../../data/Accordion/accordions-data.json";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import ArchitectureSlider from "../../components/Hero/ArchitectureSlider";
import AccordionsComponent from "../../components/Accordions/AccordionsComponent";
import AboutArchitecture from "../../components/AboutUs/AboutArchitecture";
import FunFactsArchitecture from "../../components/FunFacts/FunFactsArchitecture";
import ServicesArchitecture from "../../components/OurServices/ServicesArchitecture";
import TeamArchitecture from "../../components/Team/TeamArchitecture";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";

import CTAOne from "../../components/CTA/CTAOne";




const Architecture = () => {
  const home = useRef();
  const about = useRef();
  const sales = useRef();
  const service = useRef();
  const agents = useRef(); 
  const rentals = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
          break;
      case "sales":
        sales.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "agents":
        agents.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "rentals":
        rentals.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne scrollToSection={scrollToSection} />
      <AccordionsComponent type="color" data={dataAccordion} classAppend="mt-120" />


      <ArchitectureSlider data={dataArchitecture} ref={home} />
      <AboutArchitecture   ref={about} classAppend="mt-50" />
      
      <FunFactsArchitecture title="2022" tagline="By The Numbers" >
      The 2021 sales results were astonishing by any measure: 1,877 contracts were signed at $4 million and above, totaling $15,912,375,308--a record-shattering performance going back to 2006, when we launched this report. It is almost three times the number of deals done in 2020 and twice as many as 2019. What’s more, the totals for both signed contracts and volume are nearly 30% higher than the highest years of 2013-2015. 

      Here is how 2022 is shaping up so far:
      </ FunFactsArchitecture>
      

      <ServicesArchitecture
        title="OUR SERVICES"
        tagline="What We Do"
        ref={service}
      />

           <GalleryArchitecture
        title="MORE FEATURED PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-150"
        ref={sales}
      />

     <GalleryArchitecture
        title="RENTAL PROPERTIES"
        tagline="Currently ON OFFER"
        ref={rentals}
      />

      <ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />
      <FooterOne />
    </Loader>
  );
};



export default Architecture;
